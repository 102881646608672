body,
div {
    font-family: 'Lato', 'sans-serif';
}

@keyframes success-text {
    0% {
        top: 128px;
        opacity: 1;
        font-size: 22px;
        color: #00C853;
    }

    100% {
        top: 0;
        opacity: 0;
        font-size: 22px;
        color: #00C853;
    }
}

@keyframes wrong-text {
    0% {
        top: 128px;
        opacity: 1;
        font-size: 18px;
        color: #FA4E6A;
    }

    100% {
        top: 0;
        opacity: 0;
        font-size: 18px;
        color: #FA4E6A;
    }
}